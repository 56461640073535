import dataCenter from "../DataCenter";
import axios from "axios";
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
const service = axios.create({
    baseURL:'https://api.555by.cn/',
    timeout:3000,
})

export const getUrl3d = ()=>{
    return service.get('http://xs.dfwgame.com/geturl_3d.php?v='+new Date().getTime()).then(data=>{
        let info = data.data
        dataCenter.initUrlConf(info)
    })
}

export const reqUploadStatistics = (type,from)=>{
    let url = 'activity/sceneRecord?type=' + type
    if(from){
        url += '&from=' + from;
    }
    return service.get(url).then(data=>data.data)
}
export const getInfoByUID = (uid)=>{
    let path = 'config/fetchweb?uid=' + uid
    return service.get(path).then(data=>data.data)
}
export const checkBeforeCharge = (uid,product_id,token)=>{
    let path = 'shop/checkBeforeWebRecharge?uid=' + uid + '&product_id=' + product_id + '&token='+token;
    return service.get(path).then(data=>data.data)
}

export const postWxPayOrder = (params)=>{
    params['from'] = 'official'
    let path = 'shop/newPayOrder';
    return service.post(path,params,{}).then(data=>data.data)
}
export const postAliPayOrder = (params)=>{
    params['from'] = 'official'
    let path = 'shop/alipayOrder';
    return service.post(path,params,{}).then(data=>data.data)
}